import React from 'react';
import img from '../images/web.svg';
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Benefits = () => {

    return (
        <div id="benefits" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Benefits</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">3D prints are not just fun</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mx-auto block" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Hours - not days - for a fraction of the price</h2>
                                    <p className="text-md font-medium">
                                        By relying on your neighbors, there is no need to wait for the mailman to arrive. Hello rapid prototyping.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mx-auto block" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">The materials that you want</h2>
                                    <p className="text-md font-medium">
                                        From ABS to print-sensitive materials such as PAHT-CF, there are no limits to what you can print.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mx-auto block" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">The newest, coolest 3d printers</h2>
                                    <p className="text-md font-medium">
                                        That new, shiny $1,5000 printer that Bambu just dropped? Your neighbor just just bought it. Go ahead, use it!
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mx-auto block" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Have a 3d printer? Make money with it!</h2>
                                    <p className="text-md font-medium">
                                        Become a maker on ink3d. Fulfill print requests for your neighbors. That sweet $1,500 3D printer you bought without telling your wife about? Now you can make money with it.
                                    </p>
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Benefits;