import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#benefits">
                Benefits
            </HashLink>
            <a href="mailto:hello@ink3d.xyz" className="px-4 font-extrabold text-gray-500 hover:text-blue-900">
                Contact Us
            </a>
            <a href="https://console.ink3d.xyz" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl">
                Console
            </a>
        </>
    )
}

export default NavLinks;
